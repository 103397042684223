<template>
    <section class="traveller">
        <div class="container">
            <div class="headline">
                <h2 class="headline__title">Happy <span>Travellers</span></h2>
                <span class="headline__meta">Relax and enjoy</span>
            </div>
            <div class="traveller__glider">
                <div class="slide" v-for="(testimonial,index) in testimonials" :key="index">
                    <blockquote class="traveller__quote">
                        <span class="traveller__quote-img"><img :src="urlPath+'uploads/media/testimonial/thumbnail/140140_'+testimonial.image" alt="{{testimonial.name}}"></span>
                        <span class="traveller__quote-details">
                            <q v-html="testimonial.description"></q>
                            <cite class="quote-cite"><strong>{{testimonial.name}}</strong>[{{ testimonial.address }}]</cite>
                        </span>
                    </blockquote>
                </div>
                
            </div>
        </div>
    </section>
</template>
<script>
import jQuery from 'jquery';
import axios from 'axios';
export default {
    name:'Testimonials',
    data(){
        return{
            testimonials: undefined,
            apiPath: process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,

        }
    },
    async mounted(){
        this.getTestimonials();
    },
    methods:{
        getTestimonials(){
            axios.get(this.apiPath + 'showtestimonials')
            .then(testimonialsresponse=>{
                this.testimonials = testimonialsresponse.data;
                
            })
        }
    },
    async updated(){
		jQuery('.traveller__glider').slick({
			adaptiveHeight: true,
			mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: true,
			autoplay: false,
			autoplaySpeed: 5000
		});
	}
    
}
</script>